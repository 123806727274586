import { useNavigate } from 'react-router-dom';
import { UserGroupIcon } from '@heroicons/react/outline';
import {
  NylasCalendarEventDataFragment,
  useCreateClientSessionFromEventMutation,
} from '../../../../../generated/graphql';
import { customToast } from '../../../../components/ToastAlert/customToast';
import { CLIENT_SESSIONS_PENDING_BASE_PATH } from '../../../ClientSessions/utils/constants';
import { logger } from '../../../../../lib/logger';
import ConfirmActionDialog from '../../../../components/ConfirmActionDialog';
import { useCalendars } from '../hooks/useCalendars';

interface CreateSessionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  event: NylasCalendarEventDataFragment;
}

export const CreateSessionDialog: React.FC<CreateSessionDialogProps> = ({
  isOpen,
  onClose,
  event,
}) => {
  const navigate = useNavigate();
  const { getCalendarByEmail } = useCalendars();
  const [createClientSession, { loading: isCreating }] =
    useCreateClientSessionFromEventMutation();

  const handleCreateSession = async () => {
    try {
      const calendar = getCalendarByEmail(event.calendarId);

      if (!calendar?.id) {
        throw new Error('Calendar not found');
      }

      const response = await createClientSession({
        variables: {
          input: {
            calendarId: calendar.id,
            eventId: event.id,
          },
        },
      });

      if (response.data?.createClientSessionFromEvent.success) {
        customToast.success('Client session created successfully');
        navigate(CLIENT_SESSIONS_PENDING_BASE_PATH);
      } else {
        const error =
          response.data?.createClientSessionFromEvent.error ||
          'Unknown error creating client session';
        customToast.error(error);
      }
    } catch (error) {
      logger.error('Error creating client session:', error);
      customToast.error('Failed to create client session');
    } finally {
      onClose();
    }
  };

  return (
    <ConfirmActionDialog
      isOpen={isOpen}
      setClosed={onClose}
      title="Create client session"
      confirmButton={{
        title: 'Create',
        loadingTitle: 'Creating...',
        theme: 'primary',
        icon: UserGroupIcon,
      }}
      isLoading={isCreating}
      onConfirm={handleCreateSession}
    >
      Are you sure you want to create a client session for "{event.title}"?
    </ConfirmActionDialog>
  );
};
