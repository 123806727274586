import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePatientAuth } from '../../../contexts/PatientAuthContext';
import { useHomecomingLoader } from '../../hooks/useHomecomingLoader';
import { getClientLoginUrlWithRedirect } from '../../lib/url';

const PatientLoginToken = () => {
  const { isAuthenticated } = usePatientAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const HomecomingLoader = useHomecomingLoader();

  const redirect = searchParams.get('redirect')!;

  useEffect(() => {
    // If user is already authenticated, redirect directly
    if (isAuthenticated) {
      navigate(redirect);
      return;
    }

    // Redirect to OTP login with the redirect URL as a parameter
    const loginUrl = getClientLoginUrlWithRedirect(redirect);
    navigate(loginUrl, { replace: true });
  }, [isAuthenticated, redirect, navigate]);

  return (
    <div className="flex h-full w-full flex-row items-center justify-center py-20">
      {HomecomingLoader}
    </div>
  );
};

export default PatientLoginToken;
