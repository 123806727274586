import { useCallback, useState } from 'react';
import {
  ClientSessionIndividual,
  useGetClientSessionLazyQuery,
} from '../../../../generated/graphql';
import { SessionModalMode, SessionModalType } from '../types';
import { logger } from '../../../../lib/logger';
import { customToast } from '../../../components/ToastAlert/customToast';

export const useSessionModal = () => {
  const [modalType, setModalType] = useState<SessionModalType>(null);
  const [selectedSession, setSelectedSession] =
    useState<ClientSessionIndividual | null>(null);
  const [getClientSession] = useGetClientSessionLazyQuery();

  const handleModalOpen = useCallback(
    async (session: ClientSessionIndividual, type: SessionModalMode) => {
      try {
        // Fetch fresh session data
        const { data, error } = await getClientSession({
          variables: { id: session.id },
        });

        if (error || !data?.clientSession) {
          logger.error('Error fetching session:', error);
          customToast.error('Unable to load session details');
          return;
        }

        setSelectedSession(data.clientSession as ClientSessionIndividual);
        setModalType(type);
      } catch (err) {
        logger.error('Error in handleModalOpen:', err);
        customToast.error('Unable to load session details');
      }
    },
    [getClientSession],
  );

  const handleModalClose = useCallback(() => {
    setSelectedSession(null);
    setModalType(null);
  }, []);

  return {
    modalType,
    selectedSession,
    openModal: handleModalOpen,
    closeModal: handleModalClose,
  };
};
